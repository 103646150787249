// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-home-home-page-js": () => import("./../../../src/pages/Home/HomePage.js" /* webpackChunkName: "component---src-pages-home-home-page-js" */),
  "component---src-pages-home-main-section-js": () => import("./../../../src/pages/Home/MainSection.js" /* webpackChunkName: "component---src-pages-home-main-section-js" */),
  "component---src-pages-home-section-2-js": () => import("./../../../src/pages/Home/Section2.js" /* webpackChunkName: "component---src-pages-home-section-2-js" */),
  "component---src-pages-home-section-3-js": () => import("./../../../src/pages/Home/Section3.js" /* webpackChunkName: "component---src-pages-home-section-3-js" */),
  "component---src-pages-home-section-4-js": () => import("./../../../src/pages/Home/Section4.js" /* webpackChunkName: "component---src-pages-home-section-4-js" */),
  "component---src-pages-home-section-contact-js": () => import("./../../../src/pages/Home/SectionContact.js" /* webpackChunkName: "component---src-pages-home-section-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shared-footer-js": () => import("./../../../src/pages/shared/Footer.js" /* webpackChunkName: "component---src-pages-shared-footer-js" */),
  "component---src-pages-shared-header-js": () => import("./../../../src/pages/shared/Header.js" /* webpackChunkName: "component---src-pages-shared-header-js" */)
}

